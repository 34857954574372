import { TableConfiguration, TableFieldDefinition } from 'ah-common-lib/src/models';

export function convertColumnsToDefinition(
  columns: string[],
  configuration: TableConfiguration
): TableFieldDefinition[] {
  if (!configuration.editConfig) return configuration.tableFields;

  const out: TableFieldDefinition[] = [];
  columns = columns.length > 0 ? columns : configuration.editConfig.defaultColumns;
  configuration.editConfig.columnConfig.forEach((conf) =>
    conf.tableBlocks.forEach((b) =>
      out.push(
        ...b.tableColumns
          .map((c) => configuration.tableFields.find((f) => f.key === c)!)
          .filter((i) => i && (!conf.editable || columns.includes(i.key)))
      )
    )
  );
  return out.length ? out : configuration.tableFields;
}
