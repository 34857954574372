<script setup lang="ts">
import { cloneDeep } from 'lodash';
import { TableFieldConfiguration, TableConfiguration } from 'ah-common-lib/src/models';
import { computed, PropType, ref, watch } from 'vue';

/**
 * Table Configuration
 *
 * Presents all the possible `columns` presented on a table `config`
 *
 * Emits:
 *  columns (payload: string[]) - Sync'able table column keys
 */

const props = defineProps({
  /**
   * Full table configuration
   *
   * Defaults to empty array
   */
  config: {
    type: Object as PropType<TableConfiguration>,
    default: () => {},
  },
  /**
   * Sync'able columns selected
   *
   * Defaults to empty array
   */
  columns: {
    type: Array as PropType<string[]>,
    default: () => [],
  },
});

const emit = defineEmits({
  'update:columns': (_columns: string[]) => true,
});

const innerColumns = ref<string[]>([]);

function breakBlockInColumns(tableFields: string[]) {
  const out: TableFieldConfiguration[][] = [];
  let index = 0;

  tableFields.forEach((fieldKey) => {
    const field = props.config.tableFields.find((f) => f.key === fieldKey);
    if (!field) return;

    if (out[index]) out[index].push(field);
    else out[index] = [field];

    // FIXME - remove need for out of interface key in tableFields
    if ((field as any).break) index++;
  });

  return out;
}

function isAllBlockSelected(tableColumns: string[]) {
  return !tableColumns.find((key) => !isSelected(key));
}

function isSelected(key: string) {
  return innerColumns.value.includes(key);
}

function toggleKey(key: string, value?: boolean) {
  const index = innerColumns.value.indexOf(key);
  const containsKey = index > -1;
  const addOrRemove = value ?? !containsKey;

  if (addOrRemove && !containsKey) {
    innerColumns.value.push(key);
  } else if (!addOrRemove && containsKey) {
    innerColumns.value.splice(index, 1);
  }
  emit('update:columns', innerColumns.value);
}

const editableBlocks = computed(() => (props.config.editConfig?.columnConfig ?? []).filter((block) => block.editable));

function toggleAll(fields: any, value?: boolean) {
  fields.forEach((field: string) => {
    toggleKey(field, value);
  });
}

watch(
  () => props.columns,
  () => {
    innerColumns.value = cloneDeep(props.columns);
  },
  { immediate: true }
);
</script>

<template>
  <VRow class="table-configuration-block-container" alignH="between" v-if="config.editConfig">
    <VCol v-for="(block, index) in editableBlocks" :sm="block.cols" :key="index">
      <div class="table-configuration-block">
        <VRow
          class="block-header-row"
          v-for="tableBlock in block.tableBlocks"
          :cols="tableBlock.cols"
          :key="tableBlock.key"
        >
          <VCol class="block-header row" cols="12">
            <h4>{{ tableBlock.header }}</h4>
            <BFormCheckbox
              class="table-config-checkbox ml-auto"
              :name="`${tableBlock.key}-checkbox`"
              :checked="isAllBlockSelected(tableBlock.tableColumns)"
              @change="toggleAll(tableBlock.tableColumns, !isAllBlockSelected(tableBlock.tableColumns))"
              v-if="tableBlock.selectAll"
            >
              Select All {{ tableBlock.header }}
            </BFormCheckbox>
          </VCol>
          <VCol
            v-for="(breakedBlock, index) in breakBlockInColumns(tableBlock.tableColumns)"
            :cols="tableBlock.cols"
            :key="index"
          >
            <div v-for="item in breakedBlock" :key="item.key">
              <BFormCheckbox
                class="table-config-checkbox"
                :name="`${item.key}-checkbox`"
                :checked="isSelected(item.key)"
                @change="toggleKey(item.key)"
              >
                {{ item.header }}
              </BFormCheckbox>
            </div>
          </VCol>
        </VRow>
      </div>
    </VCol>
  </VRow>
</template>

<style lang="scss" scoped>
h4 {
  font-weight: $font-weight-bold;
}

.table-configuration-block {
  border: 1px $common-color-grey solid;
  border-radius: 3px;
  margin-bottom: 1rem;
  padding: 1rem 2.5rem;
}

.table-config-checkbox {
  margin-bottom: 1rem;
  ::v-deep {
    * {
      cursor: pointer;
    }
    label::before {
      border-radius: 50%;
    }
  }
}

.block-header,
.block-header-row:not(:last-child) {
  margin-bottom: 1rem;
}
</style>
