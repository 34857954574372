<script lang="ts" setup>
import BoxGridItem from '@/app/components/common/boxGrid/BoxGridItem.vue';
import { computed, ref } from 'vue';

const props = withDefaults(defineProps<{ itemClass?: string; overlayType?: 'managed' | 'simple' }>(), {
  itemClass: 'card-block',
  overlayType: 'managed',
});

const isError = ref<boolean>(false);

const isLoading = ref<boolean>(false);

const itemClassCalc = computed(() => [props.itemClass, { loading: isLoading.value }]);
</script>

<template>
  <BoxGridItem
    class="block-holder"
    :class="{ 'with-error': isError }"
    :isError.sync="isError"
    :isLoading.sync="isLoading"
    :overlayType="overlayType"
    v-on="$listeners"
    v-bind="{ ...$attrs, itemClass: itemClassCalc }"
  >
    <slot />
  </BoxGridItem>
</template>

<style lang="scss" scoped>
.block-holder {
  @include responsiveGutter('margin-bottom', 2.5);
  &.with-error {
    display: flex;
    ::v-deep .box-grid-item {
      width: 100%;
      border: 2px solid;
      border-radius: 8px;
      @include themedBorderColor($color-danger);
    }
  }
}
</style>
