<script setup lang="ts">
import { computed, provide, ref } from 'vue';
import VersionAlert from 'ah-common-lib/src/common/components/VersionAlert.vue';
import config from './config';
import ChatBotOverlay from '@/app/chatbot/ChatBotOverlay.vue';

// FIXME - this needs to be here to prevent a non breaking issue
// where missing Injection errors are reported in InjectReactive, even if `default` key is provided
const emptyKey = ref<null>(null);
provide('ahEmptyKey', emptyKey);

const showDevTools = computed(() => !!config.showDevTools);
</script>

<template>
  <div id="app-holder">
    <RouterView />
    <VersionAlert v-if="showDevTools" />
    <ChatBotOverlay />
  </div>
</template>
