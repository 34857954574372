import { PaginatedQuery, PaginatedResponse } from '../models';
import { Observable, of } from 'rxjs';
import { tap, mergeMap } from 'rxjs/operators';

/**
 * Loads all results from a paginated list, by making sequential requests for each page found for filter
 */
export function loadFullPaginatedList<T = any, F = any>(
  filters: PaginatedQuery<F>,
  request: (filters: PaginatedQuery<F>) => Observable<PaginatedResponse<T>>
): Observable<T[]> {
  let pageNumber = 0;
  const list: T[] = [];

  function iterateRequest(): Observable<T[]> {
    return request({ ...filters, pageNumber }).pipe(
      tap((response) => list.push(...response.list)),
      mergeMap((response) => {
        if (response.total > list.length) {
          pageNumber = pageNumber + 1;
          return iterateRequest();
        }
        return of(list);
      })
    );
  }

  return iterateRequest();
}
