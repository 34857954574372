import { render, staticRenderFns } from "./ChatBotChatRoom.vue?vue&type=template&id=440785dc&scoped=true"
import script from "./ChatBotChatRoom.vue?vue&type=script&setup=true&lang=ts"
export * from "./ChatBotChatRoom.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./ChatBotChatRoom.vue?vue&type=style&index=0&id=440785dc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "440785dc",
  null
  
)

export default component.exports