import { CachedItem } from 'ah-common-lib/src/helpers';
import {
  EntityType,
  EntitySector,
  CountryInfo,
  ISOCodeInfo,
  RoutingCodeData,
  PositionLimitsProfile,
  CurrencyData,
  LocalizedBeneficiaryFieldData,
  LocalizedFieldData,
  PaymentRailData,
  PurposeCodeResponse,
  BankingScheme,
  BeneficiaryType,
  FeePaymentType,
  PaymentLimitData,
  AMLScorecardFieldValue,
  SingleCurrency,
  HedgingInstruments,
} from 'ah-api-gateways';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import Vue from 'vue';
import { commonStoreActions } from 'ah-common-lib/src/constants/storeActions';
import { makeStoreSupportData, StoreSupportData } from './supportData';
import { deterministicJSON } from 'ah-common-lib/src/helpers/serialization';
import { FieldOption } from 'ah-common-lib/src/form/interfaces';
import { MultiOptionsSavedList } from 'ah-common-lib/src/common/components/multiSelectModal/savedList';
import { commonCustomOptionsKey } from '@/app/store/savedSettingsActions';
import { defineStore } from 'pinia';

let sd!: StoreSupportData;

export const useSettingsStore = defineStore('settingsModule', {
  persist: true,
  state: () => {
    if (!sd) {
      sd = makeStoreSupportData();
    }
    return {
      countries: new CachedItem<CountryInfo[]>(),
      paymentRails: new CachedItem<PaymentRailData[]>(),
      entityTypes: new CachedItem<EntityType[]>(),
      routingCodeTypes: new CachedItem<RoutingCodeData[]>(),
      currencyData: new CachedItem<CurrencyData[]>(),
      currencyDataSpot: {} as { [key: string]: CachedItem<SingleCurrency[]> },
      currencyDataForward: {} as { [key: string]: CachedItem<SingleCurrency[]> },
      amlScorecardFieldValues: new CachedItem<AMLScorecardFieldValue[]>(),
      savedOptionLists: {} as { [key: string]: CachedItem<MultiOptionsSavedList[]> },
      savedTableColumns: {} as { [key: string]: CachedItem<string[]> },
      localPaymentLimits: {} as { [currency: string]: CachedItem<PaymentLimitData> },
      swiftPaymentLimits: {} as { [currency: string]: CachedItem<PaymentLimitData> },
      currencyBankAccountFields: {} as { [key: string]: CachedItem<LocalizedBeneficiaryFieldData[]> },
      bankPurposeCodes: {} as { [key: string]: CachedItem<PurposeCodeResponse> },
      currencyAddressFields: {} as { [key: string]: CachedItem<LocalizedFieldData[]> },
      entitySectors: new CachedItem<EntitySector[]>(),
      limitsProfile: new CachedItem<PositionLimitsProfile | null>(),
      countryISOSubdivisions: {} as { [countryCode: string]: CachedItem<ISOCodeInfo[]> },
    };
  },
  getters: {
    displayRate() {
      return (val?: number, emptyValue: string = ''): string => {
        return typeof val === 'number' ? val.toFixed(this.ratePrecision) : emptyValue;
      };
    },
    ratePrecision() {
      return 4;
    },
    routingCodes(state) {
      return state.routingCodeTypes.item ?? [];
    },
    allCountries(state) {
      return state.countries.item ?? [];
    },
    getMultiOptionsSavedList(state) {
      return (key: commonCustomOptionsKey) => {
        return state.savedOptionLists[key]?.item || [];
      };
    },
    getSavedTableColumns(state) {
      return (key: string) => {
        return state.savedTableColumns[key]?.item || [];
      };
    },
    activeCountries(state) {
      return (state.countries.item ?? []).filter((c) => c.enabled);
    },
    entityTypesList(state) {
      return state.entityTypes.item ?? [];
    },
    currencies(state) {
      return state.currencyData.item ?? [];
    },
    getCurrenciesSpot(state) {
      return (clientId: string) => {
        return state.currencyDataSpot[clientId]?.item ?? [];
      };
    },
    getCurrenciesForward(state) {
      return (clientId: string) => {
        return state.currencyDataForward[clientId]?.item ?? [];
      };
    },
    currencyPairs(state) {
      const out: string[] = [];
      if (state.currencyData.item) {
        state.currencyData.item.forEach((ccy1) => {
          state.currencyData.item!.forEach((ccy2) => {
            if (ccy1 !== ccy2) out.push(`${ccy1.currency}${ccy2.currency}`);
          });
        });
      }
      return out;
    },
    currenciesAsOptions() {
      return (withName = true) => {
        return (this.currencies ?? []).map((i: CurrencyData) => ({
          label: `${i.currency} ${withName ? i.name : ''}`,
          value: i.currency,
        })) as FieldOption[];
      };
    },
    clientLimitsProfile(state) {
      return state.limitsProfile.item ?? null;
    },
    getPaymentLimit(state) {
      return (currency: string, bankingScheme = BankingScheme.LOCAL) => {
        if (bankingScheme === BankingScheme.LOCAL) {
          return state.localPaymentLimits[currency]?.item;
        } else {
          return state.swiftPaymentLimits[currency]?.item;
        }
      };
    },
    countrySubdivisions(state) {
      return (countryCode: string) => state.countryISOSubdivisions[countryCode]?.item || [];
    },
    getCountryCurrency(state) {
      return (cc: string) => {
        if (!state.countries.item) {
          return null;
        }
        return state.countries.item.find((i) => i.cc === cc)?.currency ?? null;
      };
    },
    getPaymentRail(state) {
      return (params?: { currency?: string; bankingScheme?: BankingScheme; type?: FeePaymentType }) => {
        if (!state.paymentRails.item) return [];
        return state.paymentRails.item.filter((rail) => {
          if (params?.currency && rail.currency !== params.currency) return false;
          if (params?.bankingScheme && rail.bankingScheme !== params.bankingScheme) return false;
          if (params?.type && rail.type !== params.type) return false;
          return true;
        });
      };
    },
  },
  actions: {
    loadPaymentRails(force = false) {
      return CachedItem.loadStoreCachedItem(this, 'paymentRails', sd.s.bankingReference.listPaymentRails({}), force);
    },
    loadPaymentLimit(payload: { force: boolean; currency: string; bankingScheme: BankingScheme }) {
      if (!payload.force) {
        const limit = this.getPaymentLimit(payload.currency, payload.bankingScheme);

        if (limit) {
          return Promise.resolve(limit);
        }
      }

      Vue.set(
        payload.bankingScheme === BankingScheme.LOCAL ? this.localPaymentLimits : this.swiftPaymentLimits,
        payload.currency,
        new CachedItem<PaymentLimitData>()
      );

      // This endpoint will only be called when a obo action is being done
      // thus the obo service
      return CachedItem.loadCachedItem(
        payload.bankingScheme === BankingScheme.LOCAL
          ? this.localPaymentLimits[payload.currency]
          : this.swiftPaymentLimits[payload.currency],
        sd.s.bankingReferenceOBO.getPaymentLimit({
          beneficiaryCurrency: payload.currency,
          beneficiaryBankingScheme: payload.bankingScheme,
        }),
        !!payload.force
      );
    },
    loadCountries(force = false) {
      return CachedItem.loadStoreCachedItem(
        this,
        'countries',
        sd.s.customerReference
          .listCountries({ pageSize: 500 })
          .pipe(map((i) => i.list.filter((country) => country.cc && country.currency))),
        force
      );
    },
    loadAMLScorecardFieldValues(force = false) {
      return CachedItem.loadStoreCachedItem(
        this,
        'amlScorecardFieldValues',
        sd.s.compliance.listAMLScorecardFieldValues({ pageSize: 2000 }).pipe(map((i) => i.list)),
        force
      );
    },
    loadTradeableCurrencies(force = false) {
      return CachedItem.loadCachedItem(
        this.currencyData,
        sd.s.bankingReference
          .listCurrencies()
          .pipe(map((currencies) => currencies.sort((a, b) => a.currency.localeCompare(b.currency)))),
        force
      );
    },
    loadTradeableCurrenciesForSpot(payload: { force?: boolean; oboClientId?: string } = { force: false }) {
      if (!payload.oboClientId) {
        throw 'You must set the client id to access list of currencies';
      }

      if (!this.currencyDataSpot[payload.oboClientId]) {
        Vue.set(this.currencyDataSpot, payload.oboClientId, new CachedItem());
      }

      return CachedItem.loadCachedItem(
        this.currencyDataSpot[payload.oboClientId],
        sd.s.liquidityProvider
          .listCurrencies(HedgingInstruments.FX_SPOT, payload.oboClientId)
          .pipe(map((currencies) => currencies.sort((a, b) => a.currency.localeCompare(b.currency)))),
        payload.force
      );
    },
    loadTradeableCurrenciesForForward(payload: { force?: boolean; oboClientId?: string } = { force: false }) {
      if (!payload.oboClientId) {
        throw 'You must set the client id to access list of currencies';
      }

      if (!this.currencyDataForward[payload.oboClientId]) {
        Vue.set(this.currencyDataForward, payload.oboClientId, new CachedItem());
      }

      return CachedItem.loadCachedItem(
        this.currencyDataForward[payload.oboClientId],
        sd.s.liquidityProvider
          .listCurrencies(HedgingInstruments.FX_FORWARD, payload.oboClientId)
          .pipe(map((currencies) => currencies.sort((a, b) => a.currency.localeCompare(b.currency)))),
        payload.force
      );
    },
    loadCurrencyBankAccountFields(payload: {
      params: {
        currency: string;
        bankingScheme?: BankingScheme;
        beneficiaryType: BeneficiaryType;
        bankCountry: string;
      };
      force?: boolean;
    }) {
      const key = deterministicJSON(payload.params);
      if (!this.currencyBankAccountFields[key]) {
        Vue.set(this.currencyBankAccountFields, key, new CachedItem());
      }
      return CachedItem.loadCachedItem(
        this.currencyBankAccountFields[key],
        sd.s.bankingReference.listBeneficiariesFields(payload.params, { errors: { silent: true } }),
        !!payload.force
      );
    },
    loadBankPurposeCodes(payload: {
      params: {
        currency: string;
        bankAccountCountry: string;
        beneficiaryType: BeneficiaryType;
        bankingScheme: BankingScheme;
      };
      force?: boolean;
    }) {
      const key = deterministicJSON(payload.params);
      if (!this.bankPurposeCodes[key]) {
        Vue.set(this.bankPurposeCodes, key, new CachedItem());
      }
      return CachedItem.loadCachedItem(
        this.bankPurposeCodes[key],
        sd.s.bankingReference.listPurposeCodes(payload.params, { errors: { silent: true } }),
        !!payload.force
      );
    },
    loadEntityTypes(force = false) {
      return CachedItem.loadCachedItem(
        this.entityTypes,
        sd.s.customerReference.listEntityTypes({ pageSize: 10000 }).pipe(map((i) => i.list)),
        force
      );
    },
    loadRoutingCodeTypes(force = false) {
      return CachedItem.loadCachedItem(
        this.routingCodeTypes,
        sd.s.bankingReference.listRoutingCodes({ errors: { silent: true } }),
        force
      );
    },
    loadEntitySectors(force = false) {
      return CachedItem.loadCachedItem(
        this.entitySectors,
        sd.s.customerReference.listEntitySectors({ pageSize: 20000 }).pipe(map((i) => i.list)),
        force
      );
    },
    loadClientLimitsProfile(payload: { force: boolean; clientId?: string } = { force: false }) {
      if (!payload.clientId) {
        throw 'Client id must be defined to load credit profiles!';
      }

      if (!this.limitsProfile) {
        this.limitsProfile = new CachedItem<PositionLimitsProfile>();
      }
      return CachedItem.loadCachedItem(
        this.limitsProfile,
        sd.s.limits
          .getClientLimitProfile(payload.clientId, {
            errors: { silent: true },
          })
          .pipe(
            catchError(() => {
              return of(null);
            })
          ),
        payload.force
      );
    },
    loadISOCodes(payload: { force?: boolean; countryCode: string }): Promise<ISOCodeInfo[]> {
      if (!this.countryISOSubdivisions[payload.countryCode]) {
        Vue.set(this.countryISOSubdivisions, payload.countryCode, new CachedItem<ISOCodeInfo[]>());
      }
      return CachedItem.loadCachedItem(
        this.countryISOSubdivisions[payload.countryCode],
        sd.s.customerReference.listISOCodes({ countryCode: payload.countryCode }),
        payload.force ?? false
      );
    },
    loadCurrencyAddressFields(payload: {
      params: {
        currency: string;
        bankingScheme?: BankingScheme;
        beneficiaryType: BeneficiaryType;
      };
      force?: boolean;
    }) {
      const key = deterministicJSON(payload.params);
      if (!this.currencyAddressFields[key]) {
        Vue.set(this.currencyAddressFields, key, new CachedItem<LocalizedFieldData[]>());
      }
      return CachedItem.loadCachedItem(
        this.currencyAddressFields[key],
        // FIXME - due to a bug in the API we need to use the public gateway on address fields
        sd.s.customerReferenceOBO.listAddressFields(payload.params),
        !!payload.force
      );
    },
    async [commonStoreActions.onLogin]() {
      this.loadCountries(true);
    },
    async [commonStoreActions.onLogout]() {
      this.clearStore();
    },
    async [commonStoreActions.onLogoutOtherTab]() {
      this.clearStore();
    },
    clearStore() {
      this.currencyDataForward = {};
      this.currencyDataSpot = {};
    },
    setMultiOptionsSavedList(payload: { key: commonCustomOptionsKey; value: MultiOptionsSavedList[] }) {
      // FIXME - should be saved in and get from API
      if (!this.savedOptionLists[payload.key]) {
        Vue.set(this.savedOptionLists, payload.key, new CachedItem<MultiOptionsSavedList[]>());
      }
      CachedItem.setCachedItem(this.savedOptionLists[payload.key], payload.value);
    },

    setSavedTableColumns(payload: { key: string; value: string[] }) {
      // FIXME - should be saved in and get from API
      if (!this.savedTableColumns[payload.key]) {
        Vue.set(this.savedTableColumns, payload.key, new CachedItem<string[]>());
      }
      CachedItem.setCachedItem(this.savedTableColumns[payload.key], payload.value);
    },
  },
});
