var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.BaseDynamicTable,_vm._g(_vm._b({ref:"table",class:[
    'ah-panel-dynamic-table',
    'table',
    {
      'table-bordered': _vm.bordered !== false,
      'table-stacked':
        !_vm.withFlexScroll && !_vm.withSimpleScroll && _vm.$mediaQuery.expr(`(max-width: calc(${_vm.breakpoint} - 0.02px))`),
    },
    _vm.tableClass,
  ],attrs:{"fields":_setup.tableFields,"animateCols":_vm.animateCols,"dataLoadState":_vm.dataLoadState,"withFlexScroll":_vm.withFlexScroll,"withSimpleScroll":_vm.withSimpleScroll,"withRowDetails":_vm.withRowDetails,"customSearch":_vm.customSearch,"primaryKey":_vm.primaryKey,"data":_vm.data,"details-td-class":_setup.detailsTdClass,"itemsLabel":_vm.itemsLabel,"selectedItems":_vm.selectedItems,"thead-class":"ah-panel-sortable","rowClass":_setup.ahPanelRowClass,"expandedRows":_setup.expandedRows,"breakpoint":_vm.breakpoint},on:{"update:expandedRows":function($event){_setup.expandedRows=$event},"update:expanded-rows":function($event){_setup.expandedRows=$event}},scopedSlots:_vm._u([{key:"head()",fn:function({ fieldData, sort, sortBy, sortDesc }){return [_c('span',{staticClass:"table-title-wrapper",on:{"click":function($event){fieldData.sortable && sort()}}},[_c('div',{class:['table-title', { 'with-sort': fieldData.sortable }]},[_c('div',{staticClass:"title-inner"},[_vm._t(`headInner(${fieldData.key})`,function(){return [_vm._v(_vm._s(fieldData.header)+" ")]}),(fieldData.info)?_c('InfoTooltip',{staticClass:"th-tooltip search-info-icon",attrs:{"text":_vm.$ahTradesState.i18n.t(fieldData.labelInfo)}}):_vm._e()],2),(fieldData.sortable)?_c('div',{staticClass:"sort-icon",class:{
            selected: sortBy && (sortBy === fieldData.key || sortBy === fieldData.sortKey),
            descending: sortBy && (sortBy === fieldData.key || sortBy === fieldData.sortKey) && sortDesc,
          }},[_c('ChevronDownIcon')],1):_vm._e()])])]}},{key:"cell(ahPanelSelectableToggle)",fn:function({ item, isSelected }){return [_c('div',{staticClass:"selectable-icon-holder"},[(_setup.hasRowSubView(item))?_c('div',{staticClass:"row-sub-view-details-button"},[(_vm.subViewTitle)?_c('p',[_vm._v(_vm._s(_vm.subViewTitle))]):_vm._e(),_c('ChevronDownIcon',{class:['selectable-icon right ', { open: isSelected }]})],1):_vm._e(),(_setup.hasRowDetails(item))?_c('ChevronDownIcon',{class:['selectable-icon', { open: _setup.hasDetailsOpen(item) }]}):_vm._e()],1)]}},{key:"cell(ahPanelRowColor)",fn:function({ item }){return [_c('div',{class:[_vm.rowColorClass ? _vm.rowColorClass(item) : '', 'color-bar']})]}},_vm._l((_vm.$scopedSlots),function(_,name){return {key:name,fn:function(scope){return [_vm._t(name,null,null,scope)]}}}),{key:"row-details",fn:function(row){return [_c('div',{class:[_vm.rowColorClass ? _vm.rowColorClass(row.item) : '', 'color-bar']}),_vm._t("row-details",null,null,row)]}}],null,true)},'BaseDynamicTable',_vm.$attrs,false),_vm.$listeners))
}
var staticRenderFns = []

export { render, staticRenderFns }