<script setup lang="ts">
/**
 * AH Panel Dynamic Table
 *
 * Replaces DynamicTable from common-lib, including some default templates for sorting and toggling
 *
 * MUST be imported into Vue as "DynamicTable" to effectively replace the base implementation
 *
 * As part of this implementation, a column names "ahPanelSelectableToggle" is added to the end of the list of columns
 * IF one isn't in already present
 */
import BaseDynamicTable, { dynamicTableProps } from 'ah-common-lib/src/common/components/listing/DynamicTable.vue';
import { TableFieldDefinition } from 'ah-common-lib/src/models';
import { computed, ref, useAttrs } from 'vue';

const table = ref<InstanceType<typeof BaseDynamicTable>>();

const props = defineProps({
  ...dynamicTableProps,
  /**
   * Row color bar class compute function
   *
   * If set, a color at the end of each row will be displayed based on the provided function
   */
  rowColorClass: {
    type: Function,
    default: null,
  },
  withRowSubView: {
    type: [Boolean, String, Function],
    default: false,
  },
  flush: {
    type: [Boolean, String],
    default: true,
  },
  tableClass: {
    type: String,
    required: false,
  },
  /**
   * Breakpoint at which the table converts to a stacked view
   *
   * Also affects the application of the `table-stacked` class
   */
  breakpoint: {
    type: String,
    default: '900px',
  },
  subViewTitle: {
    type: String,
    default: 'View Details',
  },
});

const expandedRows = ref<any[]>([]);

const tableFields = computed(() => {
  const fields: TableFieldDefinition[] = [...((useAttrs().fields as any) || [])];

  if (props.withRowDetails !== false || props.withRowSubView !== false) {
    fields.push({
      header: '',
      key: 'ahPanelSelectableToggle',
      sortable: false,
      colStyle: { width: '3em' },
    });
  }

  if (props.rowColorClass) {
    fields.push({
      header: '',
      key: 'ahPanelRowColor',
      sortable: false,
      tdClass: 'ah-table-color-block',
      thClass: 'ah-table-color-block',
    });
  }

  return fields;
});

function ahPanelRowClass(item: any, type: any) {
  const classes = [];
  if (props.rowClass) {
    classes.push(typeof props.rowClass === 'function' ? props.rowClass(item, type) : props.rowClass);
  }

  if (props.selectedItems.includes(item[props.primaryKey])) {
    classes.push('selected-row');
  }

  if (hasRowDetails(item)) {
    classes.push('with-details');
  }

  if (hasDetailsOpen(item)) {
    classes.push('b-table-has-details');
  }

  if (hasRowSubView(item)) {
    classes.push('with-subview');
  }

  return classes.join(' ');
}

const detailsTdClass = computed(() => {
  if (props.rowColorClass) {
    return ['ah-table-color-block'];
  }
  return [];
});

function hasDetailsOpen(item: any) {
  return expandedRows.value.includes(item[props.primaryKey]);
}

function hasRowDetails(item: any) {
  return typeof props.withRowDetails === 'function' ? props.withRowDetails(item) : props.withRowDetails !== false;
}

function hasRowSubView(item: any) {
  return typeof props.withRowSubView === 'function' ? props.withRowSubView(item) : props.withRowSubView !== false;
}

function toggleRowDetails(item: any, value?: boolean) {
  table.value!.toggleRowDetails(item, value);
}

defineExpose({ toggleRowDetails: toggleRowDetails });
</script>

<template>
  <BaseDynamicTable
    :class="[
      'ah-panel-dynamic-table',
      'table',
      {
        'table-bordered': bordered !== false,
        'table-stacked':
          !withFlexScroll && !withSimpleScroll && $mediaQuery.expr(`(max-width: calc(${breakpoint} - 0.02px))`),
      },
      tableClass,
    ]"
    v-on="$listeners"
    v-bind="$attrs"
    :fields="tableFields"
    :animateCols="animateCols"
    :dataLoadState="dataLoadState"
    :withFlexScroll="withFlexScroll"
    :withSimpleScroll="withSimpleScroll"
    :withRowDetails="withRowDetails"
    :customSearch="customSearch"
    :primaryKey="primaryKey"
    :data="data"
    :details-td-class="detailsTdClass"
    :itemsLabel="itemsLabel"
    :selectedItems="selectedItems"
    thead-class="ah-panel-sortable"
    :rowClass="ahPanelRowClass"
    :expandedRows.sync="expandedRows"
    :breakpoint="breakpoint"
    ref="table"
  >
    <template #head()="{ fieldData, sort, sortBy, sortDesc }">
      <span @click="fieldData.sortable && sort()" class="table-title-wrapper">
        <div :class="['table-title', { 'with-sort': fieldData.sortable }]">
          <div class="title-inner">
            <slot :name="`headInner(${fieldData.key})`">{{ fieldData.header }} </slot>
            <InfoTooltip
              v-if="fieldData.info"
              class="th-tooltip search-info-icon"
              :text="$ahTradesState.i18n.t(fieldData.labelInfo)"
            />
          </div>
          <div
            class="sort-icon"
            :class="{
              selected: sortBy && (sortBy === fieldData.key || sortBy === fieldData.sortKey),
              descending: sortBy && (sortBy === fieldData.key || sortBy === fieldData.sortKey) && sortDesc,
            }"
            v-if="fieldData.sortable"
          >
            <ChevronDownIcon />
          </div>
        </div>
      </span>
    </template>
    <!-- cell with selectable item chevron -->
    <template #cell(ahPanelSelectableToggle)="{ item, isSelected }">
      <div class="selectable-icon-holder">
        <div class="row-sub-view-details-button" v-if="hasRowSubView(item)">
          <p v-if="subViewTitle">{{ subViewTitle }}</p>
          <ChevronDownIcon :class="['selectable-icon right ', { open: isSelected }]" />
        </div>
        <ChevronDownIcon v-if="hasRowDetails(item)" :class="['selectable-icon', { open: hasDetailsOpen(item) }]" />
      </div>
    </template>

    <template #cell(ahPanelRowColor)="{ item }">
      <div :class="[rowColorClass ? rowColorClass(item) : '', 'color-bar']" />
    </template>

    <!-- slots from parent -->
    <template v-for="(_, name) in $scopedSlots" #[name]="scope">
      <slot :name="name" v-bind="scope" />
    </template>

    <!-- row-details placed after slots from parent to override row-details slot, if added-->
    <template #row-details="row">
      <div :class="[rowColorClass ? rowColorClass(row.item) : '', 'color-bar']" />
      <slot name="row-details" v-bind="row" />
    </template>
  </BaseDynamicTable>
</template>

<style lang="scss" scoped>
.ah-panel-dynamic-table ::v-deep {
  .page-item.active > .page-link {
    background-color: $primary;
    border-color: $primary;
    color: white;
  }
  thead {
    height: 100%;
    tr,
    th {
      height: inherit;
    }

    th {
      height: inherit;

      .table-title-wrapper {
        width: 100%;
        height: 100%;
        display: table-cell;

        .table-title {
          white-space: nowrap;
          height: 100%;
          vertical-align: middle;

          .title-inner {
            display: inline-block;
            white-space: pre-wrap;
            word-break: keep-all;
            vertical-align: middle;
            width: 100%;
            min-width: 100%;
          }

          &.with-sort {
            .title-inner {
              width: auto;
              max-width: 80%;
              min-width: unset;
            }
          }
        }

        .sort-icon {
          display: inline-block;
          overflow: visible;
          color: $common-color-grey;

          svg {
            rotate: 180deg;
            height: 1.4em;
            width: 1.4em;
          }

          &.descending {
            svg {
              rotate: 0deg;
            }
          }

          &.selected {
            color: black;
          }
        }
      }

      & > div {
        height: 100%;
        min-width: 100%;
        text-align: left;
        display: flex;
        line-height: 100%;
        justify-content: center;
        align-content: center;
        flex-direction: column;
      }
    }
  }

  tr {
    td.ah-table-color-block {
      min-width: 8px;
      width: 8px;
      border-top: none;
      padding: 0;
      overflow: hidden;
    }

    th.ah-table-color-block {
      min-width: 8px;
      width: 8px;
      padding: 0;
      border-bottom: none;
    }

    td.ah-table-color-block .color-bar {
      content: ' ';
      position: absolute;
      // Using subpixel positioning as 1px table border exists "between" cells
      top: -0.5px;
      bottom: 0;
      right: 0;
      border-right-width: 8px;
      border-right-style: solid;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    &.b-table-has-details td.ah-table-color-block .color-bar {
      border-bottom-right-radius: 0px;
    }

    &.b-table-details,
    &.p-datatable-row-expansion {
      td .color-bar {
        border-top-right-radius: 0px;
      }
    }
  }

  &.loading-no-items .b-table-bottom-row {
    height: 6em;
  }

  .no-results {
    font-size: 14px;
    width: 100%;
    text-align: left;
  }

  .with-details,
  .with-subview {
    cursor: pointer;
    &:hover {
      background-color: $xo-color-widgets-white-shadow;
    }
  }

  .row-sub-view-details-button {
    font-weight: $font-weight-bold;
    text-align: end;
    white-space: nowrap;
    * {
      display: inline-block;
    }
  }

  .table-list-standin {
    border-radius: 0;
  }

  .selectable-icon-holder {
    display: block;

    .selectable-icon {
      width: 1.5em;
      height: 1.5em;
      transition: transform 0.6s;

      &.right {
        transform: rotate(-90deg);
        &.open {
          transform: rotate(90deg);
        }
      }
      &.open {
        transform: rotate(180deg);
      }
    }
  }
}

.ah-panel-dynamic-table.table-stacked ::v-deep {
  .p-datatable .p-datatable-tbody > tr > td {
    border: none;

    &:last-child {
      border-bottom: 1px solid $common-color-lightGrey;
    }
  }
}
</style>
