<script setup lang="ts">
import { BModal } from 'bootstrap-vue';
import TableColumnEditor from './TableColumnEditor.vue';
import { cloneDeep } from 'lodash';
import { TableConfiguration } from 'ah-common-lib/src/models';
import { PropType, ref, watch } from 'vue';

/**
 * Table Definition Modal
 *
 * Presents a modal with a table configuration
 *
 * Emits:
 *  columns (payload: string[]) - Sync'able table column keys
 */

const props = defineProps({
  /**
   * Full table configuration
   *
   * Defaults to empty array
   */
  config: {
    type: Object as PropType<TableConfiguration>,
    default: () => {},
  },
  /**
   * Sync'able columns selected
   *
   * Defaults to empty array
   */
  columns: {
    type: Array as PropType<string[]>,
    default: () => [],
  },
  /**
   * Open modal button title
   *
   * Defaults to 'Column Configuration'
   */
  buttonTitle: {
    type: String,
    default: 'Column Configuration',
  },
});

const emit = defineEmits({
  'update:columns': (_columns: string[]) => true,
});

const columnsConfigModal = ref<InstanceType<typeof BModal>>();

const innerColumns = ref<string[]>([]);

function updateInnerColumns() {
  innerColumns.value = props.columns.length
    ? cloneDeep(props.columns)
    : cloneDeep(props.config.editConfig?.defaultColumns || []);
}

watch(() => props.columns, updateInnerColumns, { immediate: true });

function showModal() {
  columnsConfigModal.value?.show();
}

function onConfirm() {
  emit('update:columns', innerColumns.value);
  columnsConfigModal.value?.hide();
}

function onClear() {
  innerColumns.value = cloneDeep(props.config.editConfig?.defaultColumns || []);
}
</script>

<template>
  <span>
    <BModal
      v-if="config.editConfig"
      :centered="true"
      ok-title="Confirm"
      cancel-title="Clear"
      cancel-variant="secondary"
      body-class="px-5 pt-0"
      header-class="px-5 pt-4"
      footer-class="justify-content-end px-5 pt-0"
      ref="columnsConfigModal"
      @hide="updateInnerColumns"
    >
      <template #modal-title>
        <TableIcon class="table-icon" />
        <h4>Column Configuration</h4>
      </template>

      <slot name="modal-message">
        <TableColumnEditor :columns.sync="innerColumns" :config="config" />
      </slot>

      <!-- Footer -->
      <template #modal-footer>
        <div class="text-right d-inline-flex">
          <VButton class="btn-secondary mr-3" blurOnClick @click="onClear"> Clear </VButton>
          <VButton class="btn-primary" @click="onConfirm"> Confirm </VButton>
        </div>
      </template>
    </BModal>
    <slot v-bind="{ showModal }">
      <VButton blurOnClick class="btn-primary" @click="showModal">{{ buttonTitle }}</VButton>
    </slot>
  </span>
</template>

<style lang="scss" scoped>
::v-deep {
  .table-icon {
    position: absolute;
    top: 1rem;
    left: 0.5rem;
    width: 2rem;
  }

  .modal-content,
  .modal-dialog {
    min-width: 70rem;
    @include upToResolution($desktopResolution) {
      min-width: unset;
    }
  }

  .custom-control-label {
    font-size: $font-size-sm;
  }

  .btn {
    width: 100%;
  }
}
</style>
