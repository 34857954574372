import { CurrencyAmount } from 'ah-api-gateways';
import { TableFieldDefinition } from 'ah-common-lib/src/models';
import { formatCurrencyValue } from './currency';

/**
 * Removes duplicates, keeping largest values for each currency only (for width calculation purposes)
 */
export function reduceCurrencies(currencies: CurrencyAmount[] = []): CurrencyAmount[] {
  const out: CurrencyAmount[] = [];

  currencies.forEach((c) => {
    const item = out.find((i) => i.currency === c.currency);
    if (!item) {
      out.push({ ...c });
    } else {
      item.gbpAmount = Math.max(item.gbpAmount, c.gbpAmount);
      item.amount = Math.max(item.amount, c.amount);
    }
  });

  return out.filter((ccy) => ccy.gbpAmount !== 0);
}

export function currencyColumns(
  currencies: CurrencyAmount[] = [],
  colConfig?: Partial<TableFieldDefinition>,
  sortable: boolean = true,
): TableFieldDefinition[] {
  const maxWidth = (curr: CurrencyAmount) => {
    const currLength = formatCurrencyValue(curr.amount)?.length;
    const gbpLength = formatCurrencyValue(curr.gbpAmount)?.length;

    return Math.max(currLength, gbpLength);
  };

  return reduceCurrencies(currencies)
    .filter((curr) => !!curr.currency)
    .map((curr) => ({
      header: curr.currency.toUpperCase(),
      key: curr.currency + 'Balance',
      colStyle: {
        'flex-basis': `${Math.max(5, (maxWidth(curr) + 3) / 1.5)}em`,
      },
      sortable: sortable,
      sortKey: curr.currency,
      tdClass: 'currency-cell text-right',
      thClass: 'currency-cell text-right',
      ...colConfig,
    }));
}
