<script setup lang="ts">
import { useAuthStore } from '../store/authStore';
import { computed, ref, watch } from 'vue';
import ChatBotChatRoom from './ChatBotChatRoom.vue';
import { useLocalStorage, useDraggable, MaybeRefOrGetter } from '@vueuse/core';
import { clamp } from 'lodash';
import { useFEFeatureFlag } from 'ah-common-stores';

const CHAT_HEIGHT_MIN = 300;
const CHAT_CLOSED_WIDTH = 300;
const CHAT_WIDTH_MIN = 300;

const chatWidthEl = ref<HTMLDivElement>();
const chatHeightEl = ref<HTMLDivElement>();
const heightResizer = ref<HTMLDivElement>();
const widthResizer = ref<HTMLDivElement>();
const cornerResizer = ref<HTMLDivElement>();

const chatHeight = useLocalStorage('chatbot-height', 600);
const chatWidth = useLocalStorage('chatbot-width', 300);

const isChatExpanded = useLocalStorage('chatbot-expanded', false);
const showAiChatBot = useFEFeatureFlag('showAiChatBot');
const isLoggedIn = computed(() => useAuthStore().isLoggedIn);

/**
 * Watcher for login status, closing the chatbox
 *
 * This watcher PRESUMES the component exists in the root of the app, OUTSIDE routing
 * If this is not the case, the component will not exist, and therefore not be able to listen for login status changes
 */
watch(
  () => useAuthStore().isLoggedIn,
  () => (isChatExpanded.value = false)
);

function useResizeListener(element: MaybeRefOrGetter<HTMLElement | undefined>, axis: 'x' | 'y' | 'both') {
  let startY = 0;
  let startX = 0;
  let startH = 0;
  let startW = 0;

  const useX = axis === 'x' || axis === 'both';
  const useY = axis === 'y' || axis === 'both';

  return useDraggable(element, {
    onStart(position, e) {
      chatWidthEl.value!.style.transition = 'width 0s';
      chatHeightEl.value!.style.transition = 'height 0s';
      chatWidth.value = clamp(chatWidth.value, 0, chatWidthEl.value!.getBoundingClientRect().width);
      chatHeight.value = clamp(chatHeight.value, 0, chatHeightEl.value!.getBoundingClientRect().height);
      startX = e.clientX - position.x;
      startW = chatWidth.value;
      startY = e.clientY - position.y;
      startH = chatHeight.value;
    },
    onEnd: () => {
      chatWidthEl.value!.style.transition = '';
      chatHeightEl.value!.style.transition = '';
    },
    onMove(position) {
      useX && (chatWidth.value = Math.max(startW + startX - position.x, CHAT_WIDTH_MIN));
      useY && (chatHeight.value = Math.max(startH + startY - position.y, CHAT_HEIGHT_MIN));
      setTimeout(() => {
        chatWidth.value = clamp(chatWidth.value, 0, chatWidthEl.value!.getBoundingClientRect().width);
        chatHeight.value = clamp(chatHeight.value, 0, chatHeightEl.value!.getBoundingClientRect().height);
      });
    },
  });
}

useResizeListener(heightResizer, 'y');
useResizeListener(widthResizer, 'x');
useResizeListener(cornerResizer, 'both');
</script>

<template>
  <div
    class="chatbot-overlay"
    ref="chatWidthEl"
    v-if="showAiChatBot && isLoggedIn"
    :style="{ width: `${isChatExpanded ? chatWidth : CHAT_CLOSED_WIDTH}px` }"
  >
    <div class="resiser height-resiser" ref="heightResizer" v-if="isChatExpanded"></div>
    <div class="resiser width-resiser" ref="widthResizer" v-if="isChatExpanded"></div>
    <div class="resiser corner-resiser" ref="cornerResizer" v-if="isChatExpanded"></div>
    <div class="card">
      <div class="chatroom-header card-header d-flex justify-content-between align-items-start">
        <span
          :class="['header-click-target', { 'full-width': !isChatExpanded }]"
          @click="isChatExpanded = !isChatExpanded"
        ></span>
        <span><i class="fa fa-robot mr-2" />Intelligent Sales Agent</span>
        <span class="open-toggle no-bottom-margin">
          <i class="fa-solid fa-angle-up" :class="{ 'rotate-down': isChatExpanded }"></i>
        </span>
      </div>
      <div class="chatroom-holder" ref="chatHeightEl">
        <div class="chatroom-holder-inner" :style="{ height: `${isChatExpanded ? chatHeight : '0'}px` }">
          <ChatBotChatRoom class="chatroom" v-if="isChatExpanded" />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.chatbot-overlay {
  position: fixed;
  transition: width 0.3s;
  bottom: -1px;
  right: 1rem;
  pointer-events: painted;
  z-index: 999;
  max-height: 80%;
  max-width: 80%;
  display: grid;
  grid-template: 'card' 100% / 100%;

  > .card {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: none;
    box-shadow: $box-shadow;
    overflow: hidden;
    grid-area: card;
    display: grid;
    grid-template-areas:
      'header'
      'chat';
    grid-template-rows: min-content 1fr;

    .card-header {
      background: $common-color-darkGrey;
      color: $common-color-white;
      grid-area: header;
    }
  }

  .chatroom-header {
    grid-area: header;
    position: relative;
    padding-right: 3em;
    padding-top: 0.3em;
    padding-bottom: 0.3em;

    .open-toggle {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: 3em;
      display: flex;
      justify-content: center;
      align-items: center;

      i {
        transition: 0.2s ease;

        &.rotate-down {
          transform: rotate(180deg);
        }
      }
    }

    .header-click-target {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: 3em;
      cursor: pointer;
      z-index: 1;

      &.full-width {
        width: 100%;
      }
    }
  }

  .chatroom-holder {
    min-width: 100%;
    max-height: 100%;
    overflow: hidden;
    grid-area: chat;
    transition: height 0.3s;

    // We use display: flex to make the chat height expand to the bottom of the screen,
    // preventing a flash of content when the chat is expanded over the limits (i.e it will not disappear below the bottom of the screen for one frame)
    display: flex;
    align-items: flex-end;

    .chatroom-holder-inner {
      width: 100%;

      .chatroom {
        height: 100%;
      }
    }
  }

  .resiser {
    position: absolute;
    user-select: none;
    z-index: 1;

    &.height-resiser {
      top: -7px;
      height: 14px;
      left: 0;
      right: 0;
      cursor: ns-resize;
    }

    &.width-resiser {
      left: -7px;
      width: 14px;
      top: 0;
      bottom: 0;
      cursor: ew-resize;
    }

    &.corner-resiser {
      left: -7px;
      top: -7px;
      width: 24px;
      height: 24px;
      z-index: 2;
      cursor: nwse-resize;
    }
  }
}
</style>
