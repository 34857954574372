import './styles/style.scss';

import Vue from 'vue';
import messages from 'ah-common-lib/src/constants/messages';
import VueI18n from 'vue-i18n';
import { Toast, Icons, Common } from 'ah-common-lib';
import {
  NavPlugin,
  CollapsePlugin,
  CardPlugin,
  TablePlugin,
  BreadcrumbPlugin,
  OverlayPlugin,
  TabsPlugin,
  TooltipPlugin,
  ModalPlugin,
  BadgePlugin,
  DropdownPlugin,
  LayoutPlugin,
  ListGroupPlugin,
  InputGroupPlugin,
  FormCheckboxPlugin,
  FormGroupPlugin,
  PopoverPlugin,
} from 'bootstrap-vue';

import App from './App.vue';

import { getRouter } from './app/router/router';
import { setupStore } from './app/store';
import { getServices } from '@/services';
import { injectAppGlobals } from './injectGlobals';
import fitText from 'ah-common-lib/src/directives/fitText';
import errorHandler from 'ah-common-lib/src/helpers/errorHandler';
import { from } from 'rxjs';
import { OtpPlugin, OtpConfig } from 'ah-common-lib/src/otp';
import { EventBus } from 'ah-common-lib/src/eventBus';
import { tabSyncPlugin } from 'ah-common-lib/src/tabSync';
import breakpoints from '@/styles/breakpointsExport.module.scss';
import mediaQuery from 'ah-common-lib/src/mediaQuery';
import CommonPlugin from '@/app/components/common';
import IconsPlugin from '@/app/components/common/icons';
import { AhWalletsPlugin, AhWalletsPluginOptions } from 'ah-wallets';
import { AhBeneficiariesPlugin, AhBeneficiariesPluginOptions } from 'ah-beneficiaries';
import { AhTradesPlugin, AhTradesPluginOptions } from 'ah-trades';
import { AhReportsPlugin, AhReportsPluginOptions } from 'ah-reports';
import { AhNotificationsPlugin, AhNotificationsPluginOptions } from 'ah-notifications';
import onIntersect from 'ah-common-lib/src/directives/intersect';
import InfoTooltip from '@/app/components/common/InfoTooltip.vue';
import { makeStoreSupportData } from './app/store/supportData';
import { setNotificationsModuleSupportDataFactory, useNotificationsStore } from 'ah-notifications/src/store';
import { setAsyncFileDownloadReaction } from 'ah-common-lib/src/listing/listingConfig';
import { DocumentExport } from 'ah-api-gateways';
import { AhAppUpdaterPlugin, AhAppUpdaterPluginOptions } from 'ah-app-updater';
import { createPinia, PiniaVuePlugin } from 'pinia';
import { useSettingsStore } from './app/store/settingsModule';
import { useAuthStore } from './app/store/authStore';
import { useWalletsStore } from './app/store/walletsModule';
import {
  setupFeatureFlagStore,
  setupLiquidityProviderStore,
  useFeatureFlagStore,
  useTabSyncSafePersistence,
} from 'ah-common-stores';
import { setOnBehalfOfOptions } from 'ah-common-lib/src/onBehalfOf/OnBehalfOf';
import config from './config';
import FormsPlugin, { FormPluginOptions } from 'ah-common-lib/src/form/FormsPlugin';

// Pinia must be created before store setup, so the current hooks are called at the right time
const pinia = createPinia();
useTabSyncSafePersistence(pinia);

Vue.use(NavPlugin);
Vue.use(CollapsePlugin);
Vue.use(CardPlugin);
Vue.use(TablePlugin);
Vue.use(OverlayPlugin);
Vue.use(BadgePlugin);
Vue.use(TabsPlugin);
Vue.use(TooltipPlugin);
Vue.use(ModalPlugin);
Vue.use(BreadcrumbPlugin);
Vue.use(DropdownPlugin);
Vue.use(LayoutPlugin);
Vue.use(ListGroupPlugin);
Vue.use(InputGroupPlugin);
Vue.use(FormCheckboxPlugin);
Vue.use(FormGroupPlugin);
Vue.use(PopoverPlugin);
Vue.use(PiniaVuePlugin);

Vue.use(Icons.IconsPlugin);
Vue.use(fitText);
Vue.use(Common.CommonComponentsPlugin);
Vue.use(EventBus);
Vue.use(FormsPlugin, {
  defaultState: {
    fieldCommon: {
      fieldWrapperClass: 'col col-12',
      formWrapperClass: 'col col-12',
      tooltipComponent: InfoTooltip,
    },
  },
} as FormPluginOptions);

Vue.use(Toast.ToastPlugin, {
  toastOpts: {
    autoHideDelay: 5000,
    position: 'bottom-right',
    keepOnHover: true,
  },
});

Vue.config.errorHandler = errorHandler;

Vue.use(CommonPlugin);
Vue.use(IconsPlugin);
Vue.use(tabSyncPlugin);

injectAppGlobals();

setNotificationsModuleSupportDataFactory((data) => makeStoreSupportData({ ...data, toast: Vue.toast }));

const services = getServices(true);
const router = getRouter(true);
Vue.prototype.$services = services;

Vue.use<OtpConfig>(OtpPlugin, {
  refreshOtpRequest: () => from(services.auth.refreshOtp()),
});

Vue.use(mediaQuery, {
  bands: {
    xsDown: `(max-width: calc(${breakpoints.sm} - 0.02px))`,
    xsOnly: `(max-width: calc(${breakpoints.sm} - 0.02px))`,
    smUp: `(min-width: ${breakpoints.sm})`,
    smDown: `(max-width: calc(${breakpoints.md} - 0.02px))`,
    smOnly: `(min-width: ${breakpoints.sm}) and (max-width: calc(${breakpoints.md} - 0.02px))`,
    mdUp: `(min-width: ${breakpoints.md})`,
    mdDown: `(max-width: calc(${breakpoints.lg} - 0.02px))`,
    mdOnly: `(min-width: ${breakpoints.md}) and (max-width: calc(${breakpoints.lg} - 0.02px))`,
    lgUp: `(min-width: ${breakpoints.lg})`,
    lgDown: `(max-width: calc(${breakpoints.xl} -0.02px))`,
    lgOnly: `(min-width: ${breakpoints.lg}) and (max-width: calc(${breakpoints.xl} -0.02px))`,
    xlUp: `(min-width: ${breakpoints.xl})`,
    xlOnly: `(min-width: ${breakpoints.xl})`,
  },
});

// i18n
Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: 'en', // set locale
  messages, // set locale messages
});

const oboServices = {
  pricingEngine: services.pricingEngineOBO,
  client: services.clientOBO,
  trade: services.tradeOBO,
  tradeReports: services.tradeReports,
  wallet: services.walletOBO,
  risk: services.riskOBO,
  limits: services.limitsOBO,
  individual: services.individualOBO,
  beneficiary: services.beneficiaryOBO,
  spreads: services.spreadsOBO,
  authz: services.authz,
  auth: services.auth,
  payments: services.paymentsOBO,
  notification: services.notificationOBO,
  financial: services.financialReports,
  rates: services.rates,
  bankingReference: services.bankingReference,
  collateralProfile: services.collateralProfile,
};

Vue.use<AhNotificationsPluginOptions>(AhNotificationsPlugin, {
  state: {
    mediaQuery: Vue.prototype.$mediaQuery,
    toast: Vue.toast,
    store: { useAuthStore, useNotificationsStore },
    services: { documents: services.documents },
  },
  directives: { onIntersect },
});

Vue.use<AhBeneficiariesPluginOptions>(AhBeneficiariesPlugin, {
  state: {
    mediaQuery: Vue.prototype.$mediaQuery,
    showDevTools: config.showDevTools,
    theme: {
      name: '',
    },
    store: { useSettingsStore, useAuthStore },
    i18n,
    services: oboServices,
    toast: Vue.toast,
  },
});

Vue.use<AhAppUpdaterPluginOptions>(AhAppUpdaterPlugin, {
  router: getRouter(),
  http: services.http,
  beforeUpdateReload() {
    return useAuthStore().waitOnRequests();
  },
  state: {
    installedVersion: window.VUE_APP_VERSION,
  },
});

Vue.use<AhTradesPluginOptions>(AhTradesPlugin, {
  state: {
    mediaQuery: Vue.prototype.$mediaQuery,
    config,
    theme: {
      tradingDeskEmail: '',
      tradingDeskPhoneNumber: '',
      displayCostTransparency: false,
    },
    showDevTools: config.showDevTools,
    store: { useWalletsStore, useSettingsStore, useAuthStore, useNotificationsStore, useFeatureFlagStore },
    i18n,
    services: {
      ...oboServices,
      credit: services.creditLimits,
      fees: services.fees,
      fxReference: services.fxReference,
      termsheetService: services.termsheetService,
      documents: services.documents,
      integrations: services.integrations,
      premiumService: services.premiumService,
    },
    toast: Vue.toast,
  },
});

Vue.use<AhWalletsPluginOptions>(AhWalletsPlugin, {
  state: {
    mediaQuery: Vue.prototype.$mediaQuery,
    store: { useWalletsStore, useSettingsStore, useNotificationsStore, useAuthStore },
    i18n,
    services: oboServices,
    toast: Vue.toast,
  },
});

Vue.use<AhReportsPluginOptions>(AhReportsPlugin, {
  state: {
    mediaQuery: Vue.prototype.$mediaQuery,
    services: {
      trade: services.trade,
      client: services.client,
      customer: services.customerReference,
      rates: services.rates,
      wallet: services.wallet,
      partner: services.partner,
    },
  },
});

setupLiquidityProviderStore({ supportData: makeStoreSupportData() });

setupFeatureFlagStore({ supportData: makeStoreSupportData() });

setOnBehalfOfOptions({
  loadClient(clientId) {
    return services.client.getClient(clientId);
  },
});

setAsyncFileDownloadReaction((document: DocumentExport) => {
  useNotificationsStore().triggerFileExportRequestNotification(document);
});

const app = new Vue({
  i18n,
  router,
  ...services,
  pinia,
  render: (h) => h(App),
});

setupStore().then(() =>
  router.onReady(() => {
    app.$mount('#app');
  })
);
