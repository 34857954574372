<script setup lang="ts">
import { Vue } from 'vue-property-decorator';
import BaseVueSelect from 'vue-select';
import cloneDeep from 'lodash/cloneDeep';
import { createPopper } from '@popperjs/core';
import { computed, PropType, ref } from 'vue';

// We need to replace the VueSelect.select method with one we can hook into
// The original select method is disregardable, as we set the value ourselves
const VueSelect = Vue.extend({
  extends: BaseVueSelect,
  methods: {
    select(option: any) {
      this.$emit('option-selected', option);
    },
  },
});

const props = defineProps({
  useSwitches: {
    type: [Boolean, String],
    default: false,
  },
  value: {
    type: Array as PropType<any[]>,
    default: () => [],
  },
  options: {
    type: Array as PropType<{ value: string; label: string }[]>,
    default: () => [],
  },
  maxFulltextLabels: {
    type: Number,
    default: 3,
  },
  itemsCountLabel: {
    type: String,
    default: 'items',
  },
  /**
   * Loading state label start
   *
   * Label presented in selector if `loading` is true.
   * This label will prepend `itemsCountLabel`.
   *
   * Default set to 'Loading'
   */
  loadingPrependLabel: {
    type: String,
    default: 'Loading',
  },
  /**
   * String to append to default placeholder
   *
   * Defaults to empty string
   */
  appendLabelToPlaceholder: {
    type: String,
    default: '',
  },
  /**
   * Current loading state
   *
   * if set, selector will be in a loading state until `loading` is false
   */
  loading: {
    type: [Boolean, String],
    default: false,
  },
  readonly: {
    type: [Boolean, String],
    default: false,
  },
  appendToBody: {
    type: [Boolean, String],
    default: false,
  },
});

const emit = defineEmits({
  'update:value': (_value: any[]) => true,
});

const active = ref<boolean>(false);

const isLoading = computed(() => props.loading !== false);

const isReadonly = computed(() => props.readonly !== false);

const itemsText = computed(() => {
  if (isLoading.value) {
    return `${props.loadingPrependLabel} ${props.itemsCountLabel}...`;
  }
  if (active.value) {
    return `Search for ${props.itemsCountLabel}...`;
  }
  if (props.value.length === 0) {
    return `All ${props.appendLabelToPlaceholder}`;
  }
  if (props.value.length <= props.maxFulltextLabels) {
    return props.value
      .map((i) => {
        const option = props.options.find((o) => o.value === i);
        return option ? option.label : i;
      })
      .join(', ');
  }
  return `${props.value.length} ${props.itemsCountLabel}`;
});

function getOptionKey(option: any) {
  return option.value || option;
}

function clearSearchOnBlur() {
  return true;
}

function onOptionSelected(option: any) {
  const index = optionIndex(option);
  const value = [...props.value];
  if (index > -1) {
    value.splice(index, 1);
  } else {
    value.push(cloneDeep(option.value));
  }
  emit('update:value', value);
}

function isOptionSelected(option: any) {
  return optionIndex(option) > -1;
}

function optionIndex(option: any) {
  return props.value.findIndex((i) => i === option.value);
}

function withPopper(dropdownList: HTMLElement, component: Vue) {
  /**
   * We need to explicitly define the dropdown width since
   * it is usually inherited from the parent with CSS.
   */
  dropdownList.style.width = `${(component.$el as HTMLElement).getBoundingClientRect().width}px`;
  const popper = createPopper(component.$refs.toggle as any, dropdownList, {
    placement: 'bottom',
    modifiers: [
      {
        name: 'positioningClass',
        enabled: true,
        phase: 'write',
        fn({ state }) {
          component.$el.classList.toggle('drop-up', state.placement === 'top');
          dropdownList.classList.toggle('drop-up', state.placement === 'top');
          dropdownList.classList.toggle('drop-down', state.placement !== 'top');
        },
      },
    ],
  });

  return () => popper.destroy();
}
</script>

<template>
  <VueSelect
    class="field-group-field-input tags-filter-select form-control"
    :value="value"
    multiple
    :clearSearchOnBlur="clearSearchOnBlur"
    @option-selected="onOptionSelected"
    @search:focus="active = true"
    @search:blur="active = false"
    :getOptionKey="getOptionKey"
    :options="options"
    :closeOnSelect="false"
    :loading="isLoading"
    :disabled="isReadonly"
    :appendToBody="appendToBody"
    :calculatePosition="withPopper"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template #option="option">
      <div
        :class="{ 'custom-checkbox': useSwitches === false, 'custom-switch': useSwitches !== false }"
        class="checkbox-holder custom-control custom-checkbox"
      >
        <input
          :id="`tags-filter-select-${option.label}`"
          :checked="isOptionSelected(option)"
          type="checkbox"
          class="custom-control-input form-control"
        />
        <label :for="`tags-filter-select-${option.label}`" class="custom-control-label">
          {{ option.label }}
        </label>
      </div>
    </template>
    <template #search="{ attributes, events }">
      <input class="vs__search" v-bind="attributes" v-on="events" :placeholder="itemsText" />
    </template>
  </VueSelect>
</template>

<style lang="scss" scoped>
.tags-filter-select {
  .checkbox-holder {
    pointer-events: none;
  }

  ::v-deep .vs__selected {
    display: none !important;
  }

  .custom-control-label {
    padding-top: 0.25em;
  }

  .simple-tag {
    font-size: $font-size-base;
  }

  .vs__search {
    border: none;
    color: $common-color-grey;
  }
}
</style>
