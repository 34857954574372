<script lang="ts" setup>
import { computed } from 'vue';

const props = withDefaults(defineProps<{ label?: string; narrow?: boolean | string; small?: boolean | string }>(), {
  label: 'Back to list',
  narrow: false,
  small: false,
});

const showLabel = computed(() => props.narrow === false && !!props.label);
</script>

<template>
  <VButton class="back-button transparent" :class="{ small: small !== false }" v-on="$listeners" v-bind="$attrs">
    <IconArrowRight class="arrow-icon" />
    <span v-if="showLabel">{{ label }}</span>
  </VButton>
</template>

<style lang="scss" scoped>
.back-button {
  text-transform: uppercase;
  font-size: $font-size-xs;
  font-weight: 700;
  line-height: 1;

  &.small {
    font-size: 10px !important;
  }

  .arrow-icon {
    transform: rotate(180deg);
    font-size: 2.5em;
    position: relative;
    top: -0.05em;
    width: 0.35em;
    margin-right: 0.2em;

    ::v-deep path {
      fill: $primary;
    }
  }
}
</style>
