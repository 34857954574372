declare global {
  interface Window {
    VUE_APP_BUILD_TIME: number;
    VUE_APP_COMMIT_HASH: string;
    VUE_APP_VERSION: string;
  }
}

export function injectAppGlobals() {
  window.VUE_APP_COMMIT_HASH = process.env.VUE_APP_COMMIT_HASH ?? '';
  window.VUE_APP_VERSION = process.env.VUE_APP_VERSION ?? '';
  window.VUE_APP_BUILD_TIME = parseInt(process.env.VUE_APP_BUILD_TIME || '');
}
