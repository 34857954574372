import { getServices, Services } from '@/services';
import {
  StoreSupportData as CommonStoreSupportData,
  makeStoreSupportData as commonMakeSupport,
} from 'ah-common-lib/src/store';
import config from '@/config';
import Vue from 'vue';

export type StoreSupportData<T = any> = CommonStoreSupportData<T, Services>;

export function makeStoreSupportData<T = any>(data: T = {} as any): StoreSupportData {
  return commonMakeSupport({
    data,
    config,
    s: getServices(),
    tabSync: Vue.tabSync,
  });
}
