import Vue from 'vue';
import { getRouter } from '../router/router';
import { checkAuth } from '../router/guards';
import { ComplianceStatus } from 'ah-api-gateways';
import { makeStoreSupportData } from './supportData';
import { runActions } from './runActions';
import { of } from 'rxjs';
import { authStoreFactory } from 'ah-common-stores';
import type { AuthStoreSupportData } from 'ah-common-stores';

export const useAuthStore = authStoreFactory({
  checkRouteAuth() {
    const router = getRouter();
    checkAuth(router.currentRoute, router.currentRoute, (location) => {
      if (location && typeof location !== 'function') {
        router.replace(location);
      }
    });
  },
  admin: true,
  getAppUpdater: () => Vue.ahAppUpdaterState,
  getOtp: () => Vue.otp,
  getRouter,
  getToast: () => Vue.toast,
  makeStoreSupportData: () => {
    const sd = makeStoreSupportData();

    const out: AuthStoreSupportData = {
      ...sd,
      s: {
        ...sd.s,
        branding: sd.s.brandingPublic,
        /** Replace public Compliance methods so they are bypassed by the auth module */
        compliance: {
          ...sd.s.compliance,
          getClientComplianceCase() {
            return of(undefined as any);
          },
          getClientComplianceStatus() {
            return of({ status: ComplianceStatus.APPROVED });
          },
        },
      },
    };

    return out;
  },
  runActions: (action) => runActions(action),
});
