export enum PaymentReconType {
  PAYMENT = 'PAYMENT',
  FUNDING = 'FUNDING',
}

export enum WalletReconType {
  PARTNER = 'PARTNER',
  CLIENT = 'CLIENT',
  HOUSE_ACCOUNT = 'HOUSE_ACCOUNT',
}
