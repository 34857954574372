import { User } from '../../models/user';
import { Address } from '../../models/common';
import { IndividualDirector } from './director';
import { PartnerSettings } from './partnerSettings';
import { VersionedObject } from '../rest';

export const SYSTEM_ID = '00000000-0000-0000-0000-000000000000';

export type RegulatoryStatus = 'UNREGULATED' | 'REGULATED';

export interface PartnerEdit {
  id: string;
  name: string;
  email: string;
  notificationsBccEmail: string;
  website: string;
  address: Address;
  registrationNumber: string;
  regulatoryStatus: RegulatoryStatus;
  directors: IndividualDirector[];
}

export interface Partner extends PartnerEdit, VersionedObject {
  settings: PartnerSettings;
  createdAt: string;
  createdBy: User;
  approvedClientCounter: number;
}
