import _Vue, { PluginObject } from 'vue';
import {
  ArrowRightIcon,
  RefreshIcon,
  IconTrade,
  DotsIcon,
  DocumentIcon,
  IconAlertCircle,
  LockIcon,
  DownloadIcon,
  InformationIcon,
} from 'ah-common-lib/src/icons/components';

export default {
  install: function install(Vue: typeof _Vue) {
    Vue.component('IconArrowRight', ArrowRightIcon);
    Vue.component('IconRefresh', RefreshIcon);
    Vue.component('IconDots', DotsIcon);
    Vue.component('IconDocument', DocumentIcon);
    Vue.component('IconTrade', IconTrade);
    Vue.component('IconAlertCircle', IconAlertCircle);
    Vue.component('IconLock', LockIcon);
    Vue.component('IconDownload', DownloadIcon);
    Vue.component('IconInfoCircle', InformationIcon);
  },
} as PluginObject<void>;
