import _Vue, { PluginObject } from 'vue';
import * as boxGridComponents from './boxGrid';
import * as tableComponents from './table';
import BackButton from './BackButton.vue';
import DashboardViewAllButton from './ViewAllButton.vue';
import LoadingOverlay from 'ah-common-lib/src/common/components/overlays/LoadingOverlay.vue';
import ManagedLoadingOverlay from 'ah-common-lib/src/common/components/overlays/ManagedLoadingOverlay.vue';
import TagMultiSelect from '@/app/components/common/form/TagMultiSelect.vue';
import SearchInput from '@/app/components/common/SearchInput.vue';
import ConfirmModal from 'ah-common-lib/src/common/components/ConfirmModal.vue';
import DataRow from '@/app/components/common/DataRow.vue';

export default {
  install: function install(Vue: typeof _Vue) {
    Vue.component('LoadingOverlay', LoadingOverlay);
    Vue.component('ManagedLoadingOverlay', ManagedLoadingOverlay);
    Vue.component('BackButton', BackButton);
    Vue.component('DashboardViewAllButton', DashboardViewAllButton);
    Vue.component('TagMultiSelect', TagMultiSelect);
    Vue.component('SearchInput', SearchInput);
    Vue.component('DataRow', DataRow);
    Vue.component('ConfirmModal', ConfirmModal);
    [boxGridComponents, tableComponents].forEach((c) => {
      Object.keys(c).forEach((key) => Vue.component(key, (c as any)[key]));
    });
  },
} as PluginObject<void>;
