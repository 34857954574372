import { VoiceTradeData } from '../tradingDesk';

export interface CreateExpiryVoiceTradeRequest {
  voiceTradeId: string;
  spotRate: number;
}

export function getConfirmationExpiryScenario(
  voiceTrade: VoiceTradeData,
  spotRate: number
): { label: string; value: number } | null {
  if (voiceTrade.sellCurrency === voiceTrade.currencyPair.slice(0, 3)) {
    if (voiceTrade.strikeRate && spotRate < voiceTrade.strikeRate) {
      return {
        label: `At expiry if the spot rate is below the strike rate (${voiceTrade.strikeRate}), the client transacts the notional ${voiceTrade.notionalCurrency} amount at the strike rate (${voiceTrade.strikeRate}).`,
        value: 1,
      };
    } else if (voiceTrade.strikeRate && spotRate >= voiceTrade.strikeRate) {
      return {
        label: `At expiry if the spot rate is above the strike rate (${voiceTrade.strikeRate}), the client has no obligation and is free to transact in the spot market.`,
        value: 2,
      };
    }
  } else {
    if (voiceTrade.strikeRate && spotRate <= voiceTrade.strikeRate) {
      return {
        label: `At expiry if the spot rate is below the strike rate (${voiceTrade.strikeRate}), the client has no obligation and is free to transact in the spot market.`,
        value: 2,
      };
    } else if (voiceTrade.strikeRate && spotRate > voiceTrade.strikeRate) {
      return {
        label: `At expiry if the spot rate is above the strike rate (${voiceTrade.strikeRate}), the client transacts the notional ${voiceTrade.notionalCurrency} amount at the strike rate (${voiceTrade.strikeRate}).`,
        value: 1,
      };
    }
  }

  return null;
}
