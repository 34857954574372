import { NavigationGuard, RawLocation } from 'vue-router';
import Vue from 'vue';
import { inheritedMeta } from 'ah-common-lib/src/helpers/router';
import { AHAdminRoute, CustomNavigationGuard, Meta } from './ahAdminRoute';
import { AuthorityType, UserRole } from 'ah-api-gateways';
import { useAuthStore } from '../store/authStore';
import { storeSetupDone } from '../store';

function calcRedirect(redirect: string, to: AHAdminRoute) {
  if (redirect.startsWith('./')) {
    let basePath = to.matched[Math.max(0, to.matched.length - 2)].path;
    if (basePath.endsWith('/')) {
      basePath = basePath.substr(0, basePath.length - 1);
    }
    return basePath + redirect.substr(1);
  }
  return redirect;
}

export const checkAuth: NavigationGuard<Vue> = (to, from, next: (to?: RawLocation) => void) => {
  storeSetupDone.then(() => {
    const authModule = useAuthStore();
    const inherited = inheritedMeta(to);
    if (inherited.auth) {
      if (!authModule.hasValidSession) {
        next({ path: '/login', query: { r: to.path, q: JSON.stringify(to.query) } });
      } else {
        next();
      }
    } else if (inherited.unAuthOnly) {
      next(authModule.isLoggedIn ? '/dashboard' : undefined);
    } else {
      next();
    }
  });
};

export const requireRouteRolesAndAuthorities: CustomNavigationGuard<Vue> = (to, from, next) => {
  storeSetupDone.then(() => {
    const authModule = useAuthStore();
    const inherited = inheritedMeta(to) as Meta;
    const roles: UserRole[] | undefined = inherited.allowedRoles;
    const requireAnyAuthorities: AuthorityType[] | undefined = inherited.requireAnyAuthorities;
    const requireAllAuthorities: AuthorityType[] | undefined = inherited.requireAllAuthorities;
    const redirect: RawLocation = calcRedirect(inherited.redirectDisallowedRole || '/dashboard?cause=403', to);

    if (!roles?.length && !requireAnyAuthorities?.length && !requireAllAuthorities?.length) {
      next();
    } else if (!authModule.loggedInRole) {
      next(redirect);
    } else if (roles && !roles.includes(authModule.loggedInRole)) {
      next(redirect);
    } else {
      authModule.loadSessionAuthorities().then(() => {
        if (requireAnyAuthorities && !authModule.hasAuthorities(requireAnyAuthorities, false)) {
          next(redirect);
        } else if (requireAllAuthorities && !authModule.hasAuthorities(requireAllAuthorities)) {
          next(redirect);
        } else {
          next();
        }
      });
    }
  });
};
