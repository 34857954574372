<script lang="ts" setup>
import kebabCase from 'lodash/kebabCase';
import mapKeys from 'lodash/mapKeys';
import { computed, ref, useAttrs } from 'vue';
import { BoxGridItemProps } from './interfaces';

const props = defineProps(BoxGridItemProps);

const attrs = useAttrs();

const overlayProps = computed(() => {
  return { variant: 'box', opacity: '0.85', ...props.loadingOverlayProps };
});

const showLoadingOverlay = computed(() => props.useLoadingOverlay !== false);

const bColAttrs = computed(() => {
  return {
    'align-self': 'start',
    ...mapKeys(attrs, (v, k) => kebabCase(k)),
  };
});

/**
 * Whether the underlying BoxGridItem's Loading overlay is in an error state
 *
 * Updated via @update:isError (untyped, via an internal v-on="$listeners")
 */
const isError = ref<boolean>(false);

/**
 * Whether the underlying BoxGridItem's Loading overlay is in a loading state
 *
 * Updated via @update:isLoading (untyped, via an internal v-on="$listeners")
 */
const isLoading = ref<boolean>(false);
</script>

<template>
  <VCol v-bind="bColAttrs" v-on="$listeners">
    <div
      :class="[
        'box-grid-item',
        itemClass,
        { 'stretch-v': bColAttrs['align-self'] === 'stretch', loading: isLoading, 'with-error': isError },
      ]"
    >
      <slot name="before-content" v-bind="{ isLoading, isError }" />
      <template v-if="showLoadingOverlay">
        <LoadingOverlay v-if="overlayType === 'simple'" v-bind="overlayProps" v-on="$listeners">
          <slot />
        </LoadingOverlay>
        <ManagedLoadingOverlay v-else-if="overlayType === 'managed'" v-bind="overlayProps" v-on="$listeners">
          <slot />
        </ManagedLoadingOverlay>
      </template>
      <slot v-else />
      <slot name="after-content" v-bind="{ isLoading, isError }" />
    </div>
  </VCol>
</template>

<style lang="scss" scoped>
.box-grid-item {
  &.stretch-v {
    min-height: 100%;
  }
}
</style>
