<script setup lang="ts">
import { onBeforeMount } from 'vue';
import { useAuthStore } from '@/app/store/authStore';
import { storeSetupDone } from '@/app/store';
import { useRouter } from 'vue-router/composables';
import { getServices } from '@/services';
import { useToast } from 'ah-common-lib/src/toast';

const props = withDefaults(defineProps<{ token?: string }>(), { token: '' });

const authStore = useAuthStore();

const router = useRouter();

const toast = useToast();

const settingsBase = '/settings';

const services = getServices();

onBeforeMount(() => {
  if (!props.token) {
    toast?.error('Incorrect email token provided');
    router.replace(`${settingsBase}/account`);
  } else {
    storeSetupDone
      .then(() => services.account.verifyEmailChange(props.token, { errors: { silent: true } }).toPromise())
      .then(
        () => {
          authStore.refreshSession();
          toast?.success('Email verified.');
          router.replace(`${settingsBase}/account`);
        },
        () => {
          toast?.error('Email confirmation failed.');
          router.replace(`${settingsBase}/account`);
        }
      );
  }
});
</script>

<template>
  <div class="email-verification">
    <h2>Verifying your email...</h2>
    <LoadingIcon class="loading-icon" />
  </div>
</template>

<style lang="scss" scoped>
.email-verification {
  margin-top: 4rem;
  text-align: center;

  .loading-icon {
    font-size: 4em;
  }
}
</style>
